import React from "react";
import "../assets/css/reviews.css";
export default function Reviews() {
  return (
    <div className="reviews-section">
      <h1>Here is what our clients say about us!</h1>
      <div className="reviews-card-container">
        <div className="review-card">
          <h1>Zomato</h1>
          <h3 style={{ color: "#f2e74e" }}>
            "Helped us achieved more than we could imagine!"
          </h3>
          <p style={{ fontWeight: "lighter" }}>
            Collaborating with LevelAid has truly been transformative. Their
            adept digital marketing strategies have propelled our brand to
            unprecedented success, driving engagement and conversions beyond our
            expectations. We're immensely grateful for their invaluable
            partnership.
          </p>
        </div>
        <div className="review-card">
          <h1>Hilz Rescue</h1>
          <h3 style={{ color: "#f2e74e" }}>
            "Level-aid gave us unbelivable results!"
          </h3>
          <p style={{ fontWeight: "lighter" }}>
            Level-aid delivered exceptional results for our digital marketing
            initiatives. Their expertise and dedication significantly enhanced
            our online presence, leading to unprecedented growth and success. We
            highly recommend their services to any organization seeking
            impactful digital marketing solutions.
          </p>
        </div>
        <div className="review-card">
          <h1>Nature's Essence</h1>
          <h3 style={{ color: "#f2e74e" }}>
            "Even we did not expect this much growth!"
          </h3>
          <p style={{ fontWeight: "lighter" }}>
            Even we did not expect this much growth! Level-aid's strategies
            propelled our brand to new heights, exceeding our expectations.
            Their insightful approach and commitment to success make them an
            invaluable partner for any business looking to thrive in the digital
            landscape.
          </p>
        </div>
      </div>
    </div>
  );
}
