import React from "react";
import '../assets/css/contactus.css'

function ContactUs() {
  return (
    <section id="contact">
      <div className="contact-container">
        <h2 style={{color:'#FEF29C'}}>Contact Us</h2>
        <p>
          Thank you for your interest in LEVELAID! We value your feedback,
          inquiries, and suggestions. Please feel free to reach out to us using
          the contact information provided below. Whether you have questions
          about our products, want to collaborate with us, or simply want to say
          hello, we're here to assist you.
        </p>

        <div class="contact-info">
          <h3>Contact Information</h3>
          <p>
            Email:{" "}
            <a href="mailto:contact@levelaid.com">contact@levelaid.com</a>
          </p>
          <p>Phone: +91</p>
        </div>

        <p>
          Our dedicated team is committed to providing you with prompt and
          helpful assistance. Whether you prefer to contact us via email or
          phone, we strive to respond to all inquiries as quickly as possible.
          Your satisfaction is our top priority, and we're here to ensure that
          your experience with LEVELAID exceeds your expectations.
        </p>

        <p>
          Additionally, if you have any feedback or suggestions for improving
          our products or services, we would love to hear from you. Your input
          helps us continuously improve and enhance the LEVELAID experience for
          all our customers.
        </p>

        <p>
          Thank you for choosing LEVELAID. We look forward to hearing from you
          soon!
        </p>

        <p>Warm regards,</p>
        <p>The LEVELAID Team</p>
      </div>
    </section>
  );
}

export default ContactUs;
