// import React from "react";
// import "./StickyButton.css";
// import { useState } from "react";

// const StickyButton = ({ label }) => {
//   const [visible, setVisible] = useState(false);
// window.addEventListener('scroll', function(){
//     const scrolled = document.documentElement.scrollTop; 
//     if (scrolled > 300){ 
//       setVisible(true) 
//     }  
//     else if (scrolled <= 300){ 
//       setVisible(false) 
//     } 
// })



//   return <button className="sticky-btn" style={{display: visible ? 'inline' : 'none'}} >{label}</button>;
// };

// export default StickyButton;

import React, { useState, useEffect } from "react";
import "./StickyButton.css";

const StickyButton = ({ label, link }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  return (
    <div className="btn-container">
      <a href={link} target="_blank" rel="noopener noreferrer">

        <button
          className={`sticky-btn ${visible ? "show" : ""}`}
        //   onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          {label}
        </button>
      </a>
    </div>
  );
};

export default StickyButton;
