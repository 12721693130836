import React from "react";
import performance from "../assets/images/services/performance-marketing.jpg"
import socialMedia from "../assets/images/services/social-media.jpeg"
import adCampaign from "../assets/images/services/ad-campaigns.webp"
import "../assets/css/services.css";
export default function Services() {
  return (
    <div className="services-section">
      <h1>Our Services</h1>
      <div className="parent-container" id="services-css">
        <div className="child-container">
          <div className="card">
            <div className="image">
              <img
                href="#"
                src={performance}
                alt=""
              />
            </div>
            <div className="content">
              <h2 style={{ color: "#F2E74E" }}>Performance Marketing</h2>
              <p>
                Elevate your brand's performance with our data-driven
                strategies, driving growth, engagement, and measurable results
                for your business.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="image">
              <img
                href="#"
                src={socialMedia}
                alt=""
              />
            </div>
            <div className="content">
              <h2 style={{ color: "#F2E74E" }}>Social Media Management</h2>
              <p>
                Maximize your online presence and engagement with our expert
                social media management services tailored to your brand's goals.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="image">
              <img
                href="#"
                src={adCampaign}
                alt=""
              />
            </div>
            <div className="content">
              <h2 style={{ color: "#F2E74E" }}>Ad Campaigns</h2>
              <p>
                Unlock the full potential of your brand's visibility and
                conversion rates with our strategic ad campaigns
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
