import './App.css';
import { Routes, Route } from 'react-router-dom';
import DisclaimerPolicy from './components/DisclaimerPolicy';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import ContactUs from './components/ContactUs'
  /**
   * Header
   *  - Logo
   * Small Intro (What we do?)
   * Companies Worked with
   *  - Logos of clients
   * Reviews (Auto-scrolling left to right)
   * Graph of improvement in client traffic
   * Book a Call now!
   * About the team
   * About the company
   */

function App() {

  return (
  <div className="app bg">
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/disclaimer-policy' element={<DisclaimerPolicy/>}/>
    <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
    <Route path='/terms-of-use' element={<TermsOfUse/>}/>
    <Route path='/contact-us' element={<ContactUs/>}/>
    </Routes>
  </div>
  );
}

export default App;
