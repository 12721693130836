import React from "react";

export default function Banner() {
  return (
    <div className="banner">
      <div className="banner-text">
        <h1>WHAT WE DO? </h1>
        <p>
          Transforming brands into legends, we are a dynamic marketing agency
          harnessing the power of creativity and strategy. From captivating
          campaigns to compelling content, we craft narratives that resonate.
          With innovative digital solutions and personalized approaches, we
          elevate brands to new heights, driving growth and leaving a lasting
          impression
        </p>
        <a href="https://forms.gle/D3nZRvfboFRp5nTF6" rel="noreferrer" target="_blank">
        <button className="connect-now-button">CONNECT NOW!</button>
        </a>
      </div>
    </div>
  );
}
