import React from "react";
import "../assets/css/footer.css";
import LevelAid from "../assets/images/levelaid.png";
import {Link} from 'react-router-dom';
import Facebook from '../assets/icons/facebook-icon.svg';
import Instagram from '../assets/icons/instagram-icon.svg';
import Linkedin from '../assets/icons/linkedin-icon.svg';
import Youtube from '../assets/icons/youtube-icon.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-row">
          <div className="footer-col">
              <div className="levelaid-footer-logo-container">
              <h1 className="yellow-font">LEVELAID</h1>
                <img src={LevelAid} alt="" className="levelaid-logo" />
              </div>
              <div className="social-links">
                <a href="#">
                  <img src={Facebook} style={{height:'40px'}}/>
                </a>
                <a href="#">
                <img src={Instagram} style={{height:'40px'}}/>
                </a>
                <a href="#">
                <img src={Linkedin} style={{height:'40px'}}/>
                </a>
                <a href="#">
                <img src={Youtube} style={{height:'40px'}}/>
                </a>
              </div>
            
          </div>
          <div className="footer-col footer-links">
            <ul>
              <li>
                <Link to="/disclaimer-policy">Disclaimer Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-use">Terms of Service</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          ©2024 Copyright. All rights reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;
