import React from "react";
import Zomato from "../assets/images/zomato.png";
import HillzRescue from "../assets/images/hilz-rescue-logo.png";
import OmSweets from "../assets/images/Om-Sweets-New-Logo.png";
import NaturesEssence from "../assets/images/natures_essence.png";
import '../assets/css/clients.css'

export default function Clients() {
  return (
    <div className="clients-banner">
      <h1>Brands who have trusted us!</h1>
      <p>Over the course of just an year, we've partnered with a lot of clients and helped them reach their maximum sales potential through our effective marketing.
        Here is just a glimpse of the ones, who are proud to have us on their side!
      </p>
      <div className="client-img-container">
        <img className="client-img"src={Zomato} alt="" style={{ height: "10rem" }} />
        <img className="client-img"src={HillzRescue} alt="" style={{ height: "10rem" }} />
        <img className="client-img"src={OmSweets} alt="" style={{ height: "10rem" }} />
        <img className="client-img"src={NaturesEssence} alt="" style={{ height: "5rem" }} />
       </div>
    </div>
  );
}
