import React from "react";
import '../assets/css/disclaimer.css'

function DisclaimerPolicy() {
  return (
    <section id="disclaimer">
    <div className="disclaimer-container">
      <h2 style={{color:'#FEF29C'}} >Disclaimer for LEVELAID</h2>
      <p>
        The information provided on the LEVELAID website (the "Website") is
        for general informational purposes only. We strive to ensure that the
        information presented is accurate and up to date, but we make no
        representations or warranties of any kind, express or implied, about
        the completeness, accuracy, reliability, suitability, or availability
        with respect to the Website or the information, products, services, or
        related graphics contained on the Website for any purpose. Any
        reliance you place on such information is therefore strictly at your
        own risk.
      </p>

      <p>
        LEVELAID does not provide any medical, legal, financial, or
        professional advice. The content on the Website is not intended to be
        a substitute for professional advice, diagnosis, or treatment. Always
        seek the advice of qualified professionals regarding any specific
        questions or concerns you may have. Reliance on any information
        provided by LEVELAID, its employees, or its contributors is solely at
        your own discretion.
      </p>

      <p>
        LEVELAID is not responsible for any errors or omissions in the content
        displayed on the Website. We do not guarantee the accuracy,
        completeness, or usefulness of any information on the Website. The
        content may be subject to change without notice.
      </p>

      <p>
        Through this website you may be able to link to other websites which
        are not under the control of LEVELAID. We have no control over the
        nature, content, and availability of those sites. The inclusion of any
        links does not necessarily imply a recommendation or endorse the views
        expressed within them.
      </p>

      <p>
        LEVELAID may provide links to external websites that are not under our
        control. We have no control over the nature, content, and availability
        of those sites. The inclusion of any links does not necessarily imply
        a recommendation or endorsement of the views expressed within them.
      </p>

      <p>
        Every effort is made to keep the Website up and running smoothly.
        However, LEVELAID takes no responsibility for, and will not be liable
        for, the Website being temporarily unavailable due to technical issues
        beyond our control.
      </p>

      <p>
        Under no circumstances shall LEVELAID, its directors, officers,
        employees, or agents be liable for any direct, indirect, incidental,
        special, consequential, or exemplary damages arising out of or in
        connection with the use of the Website or the information contained
        therein. By using the LEVELAID Website, you agree to release and hold
        harmless LEVELAID and its employees from any and all claims, damages,
        or liabilities arising from your use of the Website or reliance on the
        information provided.
      </p>

      <p>
        This Disclaimer Policy is subject to change without notice. It is your
        responsibility to review this policy periodically for any updates.
      </p>

      <p>
        If you have any questions or concerns about this Disclaimer Policy,
        please contact us at Email:{" "}
      </p>
    </div>
  </section>
  );
}

export default DisclaimerPolicy;
