import React from "react";
import "../assets/css/numbers.css";
export default function Numbers() {
  return (
    <div className="numbers-section">
      <h1>Numbers don't lie, do they?</h1>
      <div className="numbers-card-container">
        <div className="numbers-card">
          <div className="numbers-card-heading">
            <h2 style={{ color: "#f2e74e" }}>Average ROAS of 7.3</h2>
          </div>
          <p style={{ fontWeight: "lighter" }}>
            Experience exceptional results with an average Return on Ad Spend
            (ROAS) of 7.3. Elevate your marketing efforts and maximize your ROI
            with our proven strategies and expertise
          </p>
        </div>
        <div className="numbers-card">
          <div className="numbers-card-heading">
            <h2 style={{ color: "#f2e74e" }}>95+ brands, and growing</h2>
          </div>
          <p style={{ fontWeight: "lighter" }}>
            Join a thriving community of 95+ brands and counting. Experience the
            momentum of growth and success as we continue to expand and elevate
            businesses globally.
          </p>
        </div>
        <div className="numbers-card">
          <div className="numbers-card-heading">
            <h2 style={{ color: "#f2e74e" }}>Managed 8 Crores+ in Ad spend</h2>
          </div>
          <p style={{ fontWeight: "lighter" }}>
            Effectively managed over 8 Crores in advertising spend, optimizing
            campaigns for maximum ROI and driving significant results for our
            clients.
          </p>
        </div>
      </div>
    </div>
  );
}
