import React from "react";
import '../assets/css/termsofuse.css'

function TermsOfUse() {
  return (
   
    <section id="terms-of-use">
    <div className="terms-of-use-container">
      <h2 style={{color:'#FEF29C'}} >Welcome to LEVELAID!</h2>
      <p>
        By using our website and services, you agree to comply with and be
        bound by the following terms and conditions ("Terms of Service").
        Please read these Terms of Service carefully before accessing or using
        our website. If you do not agree to all the terms and conditions of
        this agreement, then you may not access the website or use any of our services.
        {/* services. If you have any questions about these Terms of Service,
        please contact us at Email:{" "}
        <a href="mailto:contact@levelaid.com">contact@levelaid.com</a>, Phone:
        +91 77609 30987. */}
      </p>

      <h3>ACCOUNT REGISTRATION</h3>
      <p>
        To use some of our services, you may be required to register for an
        account. You must provide accurate and complete information when
        registering for an account. You are solely responsible for maintaining
        the confidentiality of your account and password, and for all
        activities under your account.
      </p>

      <h3>CONTENT AND INTELLECTUAL PROPERTY</h3>
      <p>
        All content on our website, including text, graphics, logos, images,
        videos, and software, is the property of LEVELAID or its licensors and
        is protected by copyright, trademark, and other intellectual property
        laws. You may not copy, modify, distribute, or otherwise use any
        content on our website without our prior written consent.
      </p>

      <h3>USER CONTENT</h3>
      <p>
        You may be able to upload or submit content to our website, such as
        photos or comments. By submitting user content, you grant us a
        non-exclusive, transferable, sub-licensable, royalty-free, worldwide
        license to use, copy, modify, create derivative works based on,
        distribute, publicly display, publicly perform, and otherwise exploit
        in any manner such user content in all formats and distribution
        channels now known or hereafter devised (including in connection with
        LEVELAID's business and on third-party sites and services), without
        further notice to or consent from you, and without the requirement of
        payment to you or any other person or entity. You represent and
        warrant that: (i) you either are the sole and exclusive owner of all
        user content or you have all rights, licenses, consents, and releases
        that are necessary to grant to LEVELAID the rights in such user
        content, as contemplated under these Terms of Service; and (ii)
        neither the user content, nor your submission, uploading, publishing,
        or otherwise making available of such user content, nor LEVELAID's use
        of the user content as permitted herein will infringe, misappropriate,
        or violate a third party's patent, copyright, trademark, trade secret,
        moral rights, or other proprietary or intellectual property rights, or
        rights of publicity or privacy, or result in the violation of any
        applicable law or regulation.
      </p>

      <h3>PROHIBITED USES</h3>
      <p>
        You may not use our website or services for any illegal or
        unauthorized purpose, or to harass, abuse, or harm another person. You
        may not use our website or services to transmit any viruses, worms, or
        other malicious code. You may not use our website or services to post
        or transmit any content that is defamatory, obscene, pornographic, or
        otherwise objectionable.
      </p>

      <h3>DISCLAIMER OF WARRANTIES</h3>
      <p>
        Our website and services are provided on an "as is" and "as available"
        basis. We make no representations or warranties of any kind, express
        or implied, as to the operation of our website or the information,
        content, materials, or products included on our website. To the full
        extent permissible by applicable law, we disclaim all warranties,
        express or implied, including implied warranties of merchantability
        and fitness for a particular purpose.
      </p>
    </div>
  </section>
  );
}

export default TermsOfUse;
