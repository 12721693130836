import React from "react";
import "../assets/css/privacypolicy.css"

function PrivacyPolicy() {
  return (
    <section id="privacy-policy">
      <div className="privacy-policy-container">
        <h2 style={{color:'#FEF29C'}}>Privacy Policy for LEVELAID</h2>
        <p>
          LEVELAID is committed to protecting the privacy and security of the
          personal information that we collect from our users. This Privacy
          Policy outlines the types of personal information we collect, how we
          use and share this information, and the steps we take to protect the
          privacy and security of your personal information.
        </p>

        <h3>Information Collection</h3>
        <p>
          LEVELAID collects personal information when you create an account,
          place an order, participate in surveys or contests, or communicate
          with us through our website or other channels. This information may
          include your name, email address, telephone number, postal address,
          payment information, and any other information you choose to provide.
        </p>

        <h3>Use of Personal Information</h3>
        <p>
          LEVELAID uses your personal information to provide you with our
          products and services, to communicate with you about your orders and
          account, and to send you promotional and marketing materials. We may
          also use your personal information to improve our website and
          services, to analyze and understand our user base, and to detect and
          prevent fraud.
        </p>

        <h3>Sharing of Personal Information</h3>
        <p>
          LEVELAID does not sell or rent your personal information to third
          parties for marketing purposes. However, we may share your personal
          information with third-party service providers who perform services on
          our behalf, such as shipping providers, payment processors, and data
          analysis firms. We will only share the personal information necessary
          for these third parties to perform their services, and we require that
          they protect the privacy and security of your personal information and
          use it only for the purpose for which it was shared.
        </p>

        <h3>Security of Personal Information</h3>
        <p>
          LEVELAID takes steps to protect the privacy and security of your
          personal information, including using secure servers and encryption
          technologies. However, no method of transmission over the Internet or
          electronic storage is 100% secure, and we cannot guarantee the
          security of your personal information.
        </p>

        <h3>Your Rights and Choices</h3>
        <p>
          You have the right to access and correct your personal information,
          and to request that we delete your personal information. You can also
          opt out of receiving promotional and marketing communications from us
          by following the instructions in those communications.
        </p>

        <h3>Changes to This Privacy Policy</h3>
        <p>
          This Privacy Policy may be updated from time to time to reflect
          changes in our practices or applicable laws. We will provide notice of
          any material changes to this Privacy Policy by posting the revised
          Privacy Policy on our website.
        </p>

        <h3>Use of Cookies</h3>
        <p>
          LEVELAID uses cookies and other technologies to improve the
          functionality and user experience of our website and to track website
          usage and analytics. By using our website, you consent to our use of
          cookies and similar technologies in accordance with this Privacy
          Policy.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any questions about our Privacy Policy or LEVELAID, please
          contact Neeraj Kumawat, Co-Founder of LEVELAID, at email:{" "}
          {/* <a href="mailto:contact@levelaid.com">contact@levelaid.com</a>, phone:
          +917760930987. */}
        </p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
