import React from 'react'
import Banner from './Banner'
import Header from './Header'
import Services from './Services'
import Numbers from './Numbers'
import Reviews from './Reviews'
import Clients from './Clients'
import Footer from './Footer'
import StickyButton from './StickyBottom/StickyButton'

function Home() {
  return (
    <div className='homepage'>
      <StickyButton label={"Contact Now!"} link={"https://forms.gle/D3nZRvfboFRp5nTF6"}/>
        <Header/>
        <Banner/>
        <Services/>
        <Numbers/>
        <Reviews/>
        <Clients/>
        <Footer/>
    </div>
  )
}

export default Home
