import React from "react";
import LevelAid from "../assets/images/levelaid.png"

export default function Header() {
  return (
    <div className="header">
      <h1 className="yellow-font" >LEVELAID</h1>
      <div className="levelaid-logo-container">
        <img src={LevelAid} alt="" className="levelaid-logo" />
      </div>
    </div>
  );
}
